import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => {
    return (
        <Layout>
            <SEO title="blog" />
            <h3>blog</h3>
            <p>Nothing here at the moment!</p>
        </Layout>
    );
};

export default SecondPage
